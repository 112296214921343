import {
  SharedColDef,
  SharedGridComponents,
  SharedGridOptions,
  SharedGridOverlayState,
} from '@ess/shared/utils/models';

export class SharedDefaultGridOptions<T> implements SharedGridOptions {
  animateRows = true;
  context: { componentParent: T };
  domLayout: 'normal' | 'autoHeight' | 'print' | undefined = 'normal';
  suppressCellFocus = true;
  suppressClipboardPaste = true;
  enableCellTextSelection = true;
  ensureDomOrder = true;
  components: SharedGridComponents;
  noRowsOverlayComponentParams: SharedGridOverlayState = {
    placeholder: 'No rows to show',
  };
  suppressDragLeaveHidesColumns = true;

  defaultColDef: SharedColDef | undefined = {
    sortable: true,
    resizable: false,
    suppressMovable: true,
    comparator: () => 0,
    sortingOrder: ['asc', 'desc'],
  };

  static readonly defaultColumnOptions: SharedColDef = {
    toggleEnabled: true,
  };

  constructor(scope: T, overlayComponent: any) {
    this.context = { componentParent: scope };
    this.components = {
      agNoRowsOverlay: overlayComponent,
      agLoadingOverlay: overlayComponent,
    };
  }
}
